
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-color-1: #1f1f1f;
  --theme-color-2: #333333;
  --theme-color-3: #444444;
  --theme-color-4: #6c6c6c;
  --theme-color-5: #aaaaaa;
  --theme-color-6: #F2F2F2;
  --theme-color-7: #888989;
}

.theme-c1 {
  color: var(--theme-color-1);
}
.theme-c2 {
  color: var(--theme-color-2);
}
.theme-c3 {
  color: var(--theme-color-3);
}
.theme-c4 {
  color: var(--theme-color-4);
}
.theme-c5 {
  color: var(--theme-color-5);
}
.theme-c6 {
  color: var(--theme-color-6);
}
.theme-c7 {
  color: var(--theme-color-7);
}

.theme-b1 {
  background-color: var(--theme-color-1);
}
.theme-b2 {
  background-color: var(--theme-color-2);
}
.theme-b3 {
  background-color: var(--theme-color-3);
}
.theme-b4 {
  background-color: var(--theme-color-4);
}
.theme-b5 {
  background-color: var(--theme-color-5);
}
.theme-b6 {
  background-color: var(--theme-color-6);
}
.theme-b7 {
  background-color: var(--theme-color-7);
}

html {
  @apply scroll-smooth;
}

html,
body {
  font-family: "Poppins", Arial, Tahoma, sans-serif;
  font-size:14px;
  line-height:25px;
  @apply font-normal overflow-x-hidden p-0 m-0 antialiased;

  &.no-scroll {
    @apply overflow-hidden;
  }
}

.griglia {
  @apply grid gap-12;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.container {
  @apply px-8 md:px-0;
}

.griglia.grid-cols-2,
.griglia.grid-cols-3,
.griglia.grid-cols-4 {
  @apply block md:grid;

  & > div {
    @apply py-6 md:py-0;
  }
}

/* MENU */

.mainNav {
  @apply h-40 theme-b1 py-4;

  a {
    font-size:16px;
    @apply text-white transition-all duration-300; 

    &:hover {
      @apply theme-c5;
    }

    &.active {
      border-bottom:1px solid #FFF;
    }
  }

  .navBar {
    @apply flex grow justify-evenly items-center;
  }
}

#languages {
  button {
    @apply px-3 py-2;
  }
  .lang {
    a {
      @apply block theme-b6 px-3 py-2;

      &:hover {
        @apply theme-b7;
      }
    }

  }
}

/* MENU MOBILE */
.mainNav {
  @apply hidden lg:block;
}

.mainNavMobile {
  @apply block lg:hidden theme-b1 py-4;

  .Logo {
    @apply hidden md:block;
  }
  .MiniLogo {
    @apply flex md:hidden items-center;
  }

  a {

    &.active {
      color:#FFF;
    }
  }
}

body {
  position: relative;
  overflow-x: visible;
  left:0px;
  @apply transition-all duration-300;

  &.slide-left {
    left:-125px;
  }
}

#burger {
  @apply  w-6 h-4 flex flex-col justify-between cursor-pointer;

  &:before, 
  &:after,
  span {
    content:'';
    height:2px;
    @apply block bg-white transition-all duration-300;
  }

  &:hover {
    &:before, 
    &:after,
    span {
      @apply theme-b7;
    }
  }
}

#menu_mobile {
  z-index:9999;
  width:250px;
  right:-260px;
  @apply block h-full fixed top-0 theme-b1 transition-all duration-300;

  &.active {
    @apply left-auto right-0;
  }
}

#menu_mobile_overlay {
  background: rgba(0,0,0,.0);
  z-index: 9002;
  @apply fixed top-0 left-0 w-full h-screen hidden transition-all duration-300;

  &.active {
    background: rgba(0,0,0,.6);
    @apply block;
  }
}

.navBarMobile_head {
  @apply flex justify-end p-4;

  .closeBtn {
    @apply w-6 h-12 cursor-pointer;

    &:after{
      display: inline-block;
      content: "\00d7";
      @apply theme-c7 text-5xl font-extralight transition-all duration-300;

    }

    &:hover:after {
      @apply theme-c6;
    }
  }
}

.navBarMobile {
  width: 100%!important;

  li {
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(255,255,255,.03);

    a {
      color:#A6A6A6;
      display: block;
      padding: 11px 5px 10px 20px;
      text-decoration: none;
      line-height: 19px;
      @apply transition-all duration-300;

      &:hover {
        color:#FFF;
      }
    }
  }

  #languages {
    @apply pl-3;

    button {
      @apply block w-full text-left;
    }
    
    .absolute {
      background: rgba(255,255,255,.025);
      @apply relative;

      a {
        border-top: 1px solid rgba(255,255,255,.03);
        @apply bg-transparent;
      }
    }
  }
}


/* HOMEPAGE */
h1 {
  @apply text-3xl md:text-5xl;
}

h2 {
  @apply text-3xl md:text-5xl;
}

h3 {
  @apply text-2xl md:text-3xl;
}

h4 {
  @apply text-xl md:text-2xl;
}

#tema-1 {
  @apply theme-b1 text-white py-12;
}

#tema-2 {
  @apply theme-b2 text-white py-12;
}

#tema-3 {
  @apply theme-b3 text-white py-12;
}

#tema-4 {
  @apply theme-b4 text-white py-12;
}

#tema-5 {
  @apply theme-b5 text-white py-12;
}

#tema-7 {
  @apply theme-b7 text-white py-12;
}

section {
  @apply py-12;
}

#home-intro {
  @apply text-center;
}

.card {
  @apply flex flex-col items-center justify-center text-center;
}

.player-wrapper {
  aspect-ratio: 500 / 280;
  @apply relative;
}

#home-intro-cards {
  @apply gap-20 py-16 block md:grid grid-cols-1 md:grid-cols-3;

  .image {
    top:0px;
    padding-top: 5px;
    margin-bottom: 25px;
    width: 100%;

    > div {
      position: unset !important;
    }

    span {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
    }

    @apply transition-all duration-300 relative;
  }

  a:hover {
    .image {
      top:-5px;
    }
    .cardContent::before {
      width:100px;
    }
  }

  .cardContent::before {
    content: "";
    height: 2px;
    top: -15px;
    background-color: #ccc;
    @apply transition-all duration-300 relative mx-auto w-0 block;
  }
}

main#homepage {
  #tema-2 {
    .griglia {
      @apply items-center;
    }
    h2 {
      @apply pb-8;
    }
  }
  #tema-3, #tema-4, #tema-5 {
    .griglia {
      @apply items-center;
    }
    h3 {
      @apply pb-8;
    }
  }
}

#footer {
  @apply theme-b5 text-white;

  h4 {
    // font-size:24px;
    @apply text-base md:text-2xl;
  }

  .logo {
    @apply p-0;
  }

  .footer-cols {
    @apply  md:columns-2 gap-8;
    & > div {
      @apply pb-16;
    }
    div#text-1666341805 {
      @apply break-before-column;
    }
  }

  #footer-3 {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    @apply text-center;
  }
}

.contact-form {
  input[type='text'], textarea {
    @apply theme-b6 py-3;
  }
  textarea {
    @apply h-48;
  }
  input[type='radio'] {
    @apply cursor-pointer;
  }
  label {
    @apply cursor-pointer;
  }
  .form-fld {
    @apply mb-2 md:mb-0;
  }
}

.externalBtn {
  @apply theme-b1 px-6 py-2 transition-all duration-300 text-white font-semibold;

  &:hover {
    @apply theme-b4 theme-c1;
  }
}

#elenco-spezie {
  @apply md:columns-2 lg:columns-3;

  .card {
    -webkit-column-break-inside: avoid;
    @apply md:grid grid-cols-2 items-start mb-12;

    .image {
      aspect-ratio: 1280 / 920;
      @apply w-full flex justify-center items-center bg-cover mb-8 md:mb-0;

      span {
        font-size:80px;
      }
    }

    .caption {
      @apply text-left;

      ul {
        @apply list-disc ml-4 pl-4;
      }
    }
  }
}

main.innerpage {
  #tema-1 {
    @apply text-center ;

    #image-1666358087 {
      @apply  mt-12;
    }
  }

  #tema-2, #tema-3, #tema-4, #tema-5, #tema-7 {
    @apply py-24;

    h2{
      @apply text-center;
    }
  }
}

#budella-naturali-e-sintetiche {
  #tema-1 {
    @apply pb-36
  }
}

#bovino {
  @apply theme-b2 text-white py-12;

  .griglia {
    @apply block;

    & > div {
      @apply md:float-left md:w-1/3 md:mb-8 md:pl-8;
    }

    & > div:first-of-type {
      @apply md:w-2/3 mb-0;
    }
  }

  &::after {
    content:'';
    @apply clear-both block;
  }

  #image-1666366613 {
    @apply py-16;
  }
}

#equino {
  @apply theme-b3 text-white py-12;

  h2 {
    @apply text-center;
  }
}

#ovino {
  @apply theme-b7 text-white py-12;

  h2 {
    @apply text-center;
  }
}

#suino {
  @apply theme-b4 text-white py-12;

  .griglia {
    @apply block;

    & > div {
      @apply md:float-left md:w-1/3 md:mb-16 md:pl-8;
    }

    & > div:first-of-type {
      @apply md:w-2/3 md:mb-0;
    }
  }

  &::after {
    content:'';
    @apply clear-both block;
  }
}

#bovino, #suino, #equino, #ovino {
  h4 {
    @apply mb-4;
  }
}

#mosaico {
  @apply theme-b2 text-white py-24;

  .griglia {
    @apply gap-0;

    .text {
      @apply theme-b1 text-center flex justify-center items-center p-8 leading-6 md:leading-4 lg:leading-6;

      h3 {
        @apply lg:mb-8 text-xl lg:text-3xl;
      }
    }

    #text-1666369384.text,
    #text-1666369376.text,
    #text-1666369358.text {
      @apply theme-b4;
    }

    .imageContainer {
      @apply aspect-square overflow-hidden;
    }
  }
}